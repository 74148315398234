import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { DrawComponent } from './draw/draw.component';
import { MapLoaderService } from './services/map.loader.service';
import { HttpClientModule } from '@angular/common/http';
import { MapService } from './services/map.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccueilComponent } from './accueil/accueil.component';
import { AuthComponent } from './auth/auth.component';
import { AppRoutingModule } from './/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DistanceComponent } from './distance/distance.component';
import { UserService } from "./services/user.service";
import { HeaderComponent } from './header/header.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { SearchPipe } from './_pipe/search.pipe';
import { CrudService } from './services/crud.service';
import { RayonComponent } from './rayon/rayon.component';
import { GeochartComponent } from './geochart/geochart.component';
import { CartesComponent } from './cartes/cartes.component';
import { ParametresComponent } from './ui/parametres/parametres.component';
import { SegmentationComponent } from './segmentation/segmentation.component';
import { LoginTokenComponent } from './login-token/login-token.component';


@NgModule({
  declarations: [
    AppComponent,
    SearchPipe,
    AccueilComponent,
    DrawComponent,
    AuthComponent,
    PageNotFoundComponent,
    DistanceComponent,
    HeaderComponent,
    RayonComponent,
    GeochartComponent,
    CartesComponent,
    SegmentationComponent,
    LoginTokenComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCaSfdQyOwQoWtaDwtL5AMOm3eA492dg9M"
    }),
    GoogleChartsModule.forRoot("AIzaSyCaSfdQyOwQoWtaDwtL5AMOm3eA492dg9M"),
    FormsModule,
    NgSelectModule,
    HttpClientModule,
    NgbModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    GoogleMapsAPIWrapper,
    CrudService,
    MapLoaderService,
    MapService,
    UserService,
    AccueilComponent,
    ParametresComponent,
    NgxSpinnerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
