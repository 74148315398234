import { Component, AfterViewInit, OnInit } from "@angular/core";
import { MapLoaderService } from "../services/map.loader.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MapService } from '../services/map.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { AccueilComponent } from "../accueil/accueil.component";
import { ToastrService } from 'ngx-toastr';

declare var google: any;

@Component({
  selector: "app-draw",
  templateUrl: "./draw.component.html",
  styleUrls: ["./draw.component.css"]
})
export class DrawComponent implements AfterViewInit, OnInit {
  map: any;
  url = this.mapService.url;
  public show : boolean= false;
  btnEdit: number;
  checkAllPetro;
  carteDescription: string;
  carteTitre: string;
  carteLat: number;
  carteLng: number;
  polygonEdit: any = null;
  drawingManager: any;
  iconTotal = 'assets/icons/icon-total.png';
  iconDefault = 'assets/icons/icon-default.png';
  btn = "Envoyer ma carte";
  listObjets: any[] = [];
  listObjetsAEnvoyer: any[] = [];
  petroliers= this.f.petroliers;
  zones = this.f.zones;
  markers = this.f.markers;
  stations: any;
  listestations :any;
  limit: number = 11;

  cluster: boolean = true;
  typeobjets = [
    {
      id: 1,
      name: "circle"
    },
    {
      id: 2,
      name: "polygon"
    },
    {
      id: 3,
      name: "polyline"
    },
    {
      id: 4,
      name: "rectangle"
    },
    {
      id: 5,
      name: "marker"
    }
  ];


  cartes: any;
  headers = new HttpHeaders({ "Content-type": "application/json" });
  constructor(
    private httpClient: HttpClient,
    private mapService: MapService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
     private userService: UserService,
     public f: AccueilComponent
  ) {}
  ngOnInit() {
    // this.spinner.show();
    if(this.userService.checkAuthorization() ==null){
      return this.router.navigate(['/auth'])
    }
    this.chargerDatas();
    console.log(this.url);
  }
  ngAfterViewInit() {
    MapLoaderService.load().then(() => {
      this.drawBegin();
    });
  }
  drawBegin() {
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: {
        lat: this.mapService.centerLat,
        lng: this.mapService.centerLng
      },
      zoom: 8
    });

    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          "polygon",
          "circle",
          "rectangle",
          "marker",
          "polyline"
        ]
      },
      circleOptions: {
        fillColor: "#dc3545",
        fillOpacity: 0.6,
        strokeWeight: 1,
        strokeColor: "#dc3545",
        clickable: true,
        // draggable: true,
        // editable: true,
        zIndex: 1
      },
      rectangleOptions: {
        fillColor: "#28a745",
        fillOpacity: 0.6,
        strokeWeight: 1,
        strokeColor: "#28a745",
        clickable: true,
        // draggable: true,
        // editable: true,
        zIndex: 1
      },
      polylineOptions: {
        strokeWeight: 3,
        strokeColor: "#6c757d",
        clickable: true,
        // draggable: true,
        // editable: true,
        zIndex: 1
      },
      polygonOptions: {
        fillColor: "#17a2b8",
        fillOpacity: 0.6,
        strokeWeight: 1,
        strokeColor: "#17a2b8",
        clickable: true,
        // draggable: true,
        // editable: true,
        zIndex: 1
      },
      markerOptions: {
        icon: this.iconTotal,
        // draggable: true,
        // editable: true,
        zIndex: 1
      }
    });

    this.drawingManager.setMap(this.map);
    google.maps.event.addListener(
      this.drawingManager,
      "overlaycomplete",
      event => {
        // Polygon drawn
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          //this is the coordinate, you can assign it to a variable or pass into another function.
          // alert(event.overlay.getPath().getArray());
          let path = event.overlay.getPath().getArray();
          let paths = [];
          for (var i in path) {
            let latLng = {
              lat: null,
              lng: null
            };
            latLng.lat = path[i].lat();
            latLng.lng = path[i].lng();
            paths.push(latLng);
          }
          let name = prompt(
            "Veuillez donnez un titre à votre dessin",
            "Exemple: construction"
          );
          if (name != null) {
            let objet = {
              name: name,
              typeobjet_id: 2,
              paths: JSON.stringify(paths),
              overlay: event.overlay,
              center: null,
              bounds: null,
              radius: null,
              commentaire: null,
              icon: null
            };
            // var body = JSON.stringify(objet);
            this.listObjets.push(objet);
          } else {
            event.overlay.setMap(null);
            return false;
          }
        } else if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
          //this is the coordinate, you can assign it to a variable or pass into another function.
          let bound = event.overlay.getBounds();
          let radius = event.overlay.getRadius();
          var center = {
            lat: event.overlay.getCenter().lat(),
            lng: event.overlay.getCenter().lng()
          };

          let name = prompt(
            "Veuillez donnez un titre à votre dessin",
            "Exemple: construction"
          );
          if (name != null) {
            let objet = {
              name: name,
              typeobjet_id: 1,
              bounds: null,
              paths: null,
              radius: radius,
              center: JSON.stringify(center),
              overlay: event.overlay,
              commentaire: null,
              icon: null
            };
            // var body = JSON.stringify(objet);
            this.listObjets.push(objet);
          } else {
            event.overlay.setMap(null);
            return false;
          }
        } else if (event.type === google.maps.drawing.OverlayType.POLYLINE) {
          //this is the coordinate, you can assign it to a variable or pass into another function.
          let path = event.overlay.getPath().getArray();
          let paths = [];
          for (var i in path) {
            let latLng = {
              lat: null,
              lng: null
            };
            latLng.lat = path[i].lat();
            latLng.lng = path[i].lng();
            paths.push(latLng);
          }
          let name = prompt(
            "Veuillez donnez un titre à votre dessin",
            "Exemple: construction"
          );
          if (name != null) {
            let objet = {
              name: name,
              typeobjet_id: 3,
              paths: JSON.stringify(paths),
              overlay: event.overlay,
              radius: null,
              center: null,
              bounds: null,
              commentaire: null,
              icon: null
            };
            // var body = JSON.stringify(objet);
            this.listObjets.push(objet);
          } else {
            event.overlay.setMap(null);
            return false;
          }
        } else if (event.type === google.maps.drawing.OverlayType.RECTANGLE) {
          //this is the coordinate, you can assign it to a variable or pass into another function.
          let bound = event.overlay.getBounds();
          let centerMap = event.overlay.getBounds().getCenter();
          let center = {
            lat: centerMap.lat(),
            lng: centerMap.lng()
          };

          // alert(bound);
          // console.log("SELat =>" +bound.getSouthWest().lat() + "SELat =>" + bound.getSouthWest().lng());
          // console.log("NELat =>" + bound.getNorthEast().lat() + " NELng =>" + bound.getNorthEast().lng());

          let bounds = {
            j: {
              j: bound.getSouthWest().lat(),
              l: bound.getSouthWest().lng()
            },
            l: {
              j: bound.getNorthEast().lat(),
              l: bound.getNorthEast().lng()
            }
          };

          let name = prompt(
            "Veuillez donnez un titre à votre dessin",
            "Exemple: construction"
          );
          if (name != null) {
            let objet = {
              name: name,
              typeobjet_id: 4,
              bounds: JSON.stringify(bounds),
              paths: null,
              overlay: event.overlay,
              commentaire: null,
              icon: null,
              radius: null,
              center: JSON.stringify(center)
            };
            // var body = JSON.stringify(objet);
            this.listObjets.push(objet);
          } else {
            event.overlay.setMap(null);
            return false;
          }
        } else if (event.type === google.maps.drawing.OverlayType.MARKER) {
          //this is the coordinate, you can assign it to a variable or pass into another function.
            let position = event.overlay.getPosition();
            let center = {
              lat: position.lat(),
              lng: position.lng()
            };
            let name = prompt(
              "Veuillez donnez un titre à votre dessin",
              "Exemple: construction"
            );
            if (name != null) {
              let objet = {
                name: name,
                typeobjet_id: 5,
                paths: null,
                overlay: event.overlay,
                center: JSON.stringify(center),
                bounds: null,
                radius: null,
                commentaire: null,
                icon: this.iconTotal
              };
              // var body = JSON.stringify(objet);
              this.listObjets.push(objet);
            } else {
              event.overlay.setMap(null);
              return false;
            }
          }
      }
    );
  }

  delete(item: any) {
    item.overlay.setMap(null);
    this.mapService.remove(this.listObjets, item);
  }

  sliceString(val: string, taille:number) {
    if(val.length > taille) {
      return (val.substr(0,taille)+'..').toUpperCase();
    }
    return val.toUpperCase();
  }

  deleteAll() {
    for (var i in this.listObjets) {
      this.delete(this.listObjets[i]);
    }
  }
  saveListObjets() {
    localStorage.setItem("listObjets", JSON.stringify(this.listObjets));
  }
  loadListObjets() {
    if (localStorage.getItem("listObjets") != null) {
      this.listObjets = JSON.parse(localStorage.getItem("listObjets"));
    }
  }
  centrer() {
    var bounds = this.mapService.get_center(this.listObjets);
    this.map.fitBounds(bounds);
  }
  addObjects() {
    this.btn = "Envoi en cours ...";
    this.spinner.show();
    //Ces element vient en premier
    this.carteLat = this.mapService
      .get_center(this.listObjets)
      .getCenter()
      .lat();
    this.carteLng = this.mapService
      .get_center(this.listObjets)
      .getCenter()
      .lng();
    for (var i = 0; i < this.listObjets.length; i++) {
      // vient en deuxieme, important !
      this.listObjets[i].overlay = null;
    }
    var userId = JSON.parse(localStorage.getItem("user")).id;
    var body = {
      name: this.carteTitre,
      description: this.carteDescription,
      user_id: userId,
      lat: this.carteLat,
      lng: this.carteLng,
      listObjets: JSON.stringify(this.listObjets)
    };
    this.httpClient
      .post(this.url + "addobjet.json", JSON.stringify(body), {
        headers: this.headers
      })
      .subscribe(
        res => {
          if (res != null) {
            this.btn = "Envoyé avec succès !";
            this.mapService.loadObjets().then(res => {
              this.router.navigate(["/home/cartes"]);
              this.toastr.success("Enregistrement de la carte réussi", "Succès");
              this.spinner.hide();
            });
          } else {
            this.spinner.hide();
            this.btn = "Envoyer ma carte";
            this.toastr.error("Enregistrement de la carte a échoué", "Echec");
          }
        },
        err => {
          this.spinner.hide();
          this.toastr.error("Enregistrement de la carte a échoué", "Erreur serveur");
          this.btn = "Envoyer ma carte";
        }
      );
  }
  changeIcon(objet){
    objet.overlay.setIcon(objet.icon);
  }
  filtre(){
    this.show= ! this.show
  }

  viewReduce() {
    this.limit = 11;
  }
  logout() {
    this.userService.destroySession();
    this.router.navigate(['/auth']);
  }
  clusterChange() {
    this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
  }
  checkPetrolier(petrolier: any) {
    if (petrolier.check == true) {
      this.mapService.loadMarkers(
        this.stations,
        this.zones,
        this.petroliers,
        petrolier.id,
        "petrolier",
        "remplir"
      );
      this.mapService.filtrage(
        this.stations,
        this.markers,
        this.zones,
        this.petroliers
      );
      this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
    } else {
      this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
      if (this.nbrEntites("petrolier") <= 0) {
        this.mapService.loadMarkers(
          this.stations,
          this.zones,
          this.petroliers,
          petrolier.id,
          "petrolier",
          "vider"
        );
      }
      if (this.checkAllPetro === true) {
        this.checkAllPetro = false;
      }
      this.mapService.filtrage(
        this.stations,
        this.markers,
        this.zones,
        this.petroliers
      );
      this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
      this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
    }
  }
  nbrEntites(nom: string) {
    var nbr = 0;
    if (nom == "petrolier") {
      for (var i in this.petroliers) {
        if (this.petroliers[i].check == true) {
          nbr++;
        }
      }
    } else if (nom == "zone") {
      for (var i in this.zones) {
        if (this.zones[i].check == true) {
          nbr++;
        }
      }
    } else {
      return false;
    }
    return nbr;
  }
  checkZone(zone: any) {
    if (this.nbrEntites("petrolier") <= 0) {
      if (zone.check === true) {
        this.mapService.loadMarkers(
          this.stations,
          this.zones,
          this.petroliers,
          zone.id,
          "zone",
          "remplir"
        );
        this.mapService.filtrage(
          this.stations,
          this.markers,
          this.zones,
          this.petroliers
        );
        this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
      }
    } else {
      if (zone.check === true) {
        this.mapService.loadMarkers(
          this.stations,
          this.zones,
          this.petroliers,
          zone.id,
          "zone",
          "remplir"
        );
        this.mapService.filtrage(
          this.stations,
          this.markers,
          this.zones,
          this.petroliers
        );
        this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
      } else {
        this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
        if (this.checkAllPetro === true) {
          this.checkAllPetro = false;
        }
        if (this.nbrEntites("zone") <= 0) {
          this.mapService.loadMarkers(
            this.stations,
            this.zones,
            this.petroliers,
            zone.id,
            "zone",
            "vider"
          );
        }
        this.mapService.filtrage(
          this.stations,
          this.markers,
          this.zones,
          this.petroliers
        );
        this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
        this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
      }
    }
  }
  checkAll() {
    this.mapService.checkAll(this.stations, this.zones, this.petroliers);
    this.mapService.filtrage(
      this.stations,
      this.markers,
      this.zones,
      this.petroliers
    );
    this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
  }
  hideAll() {
    this.mapService.hideAll(this.stations, this.zones, this.petroliers);
    this.mapService.filtrage(
      this.stations,
      this.markers,
      this.zones,
      this.petroliers
    );
    this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
  }

  updateAll(check: boolean) {
    if (check == true) {
      this.checkAll();
    } else {
      this.hideAll();
    }
  }
  checkStation(station: any) {
    var petrolier = this.petroliers[station.petrolier_id];
    var zone = this.zones[station.zone_id];
    if (station.check === true) {
      if (petrolier.check === false) {
        petrolier.check = true;
      }
      if (zone.check === false) {
        zone.check = true;
      }
      this.mapService.filtrage(
        this.stations,
        this.markers,
        this.zones,
        this.petroliers
      );
    } else {
      this.mapService.filtrage(
        this.stations,
        this.markers,
        this.zones,
        this.petroliers
      );
      this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
    }
    this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
    this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
  }

  tailleTableau(obj) {
    var size = 0, key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  }
  chargerDatas() {
    this.spinner.show()
    this.mapService
      .init()
      .then(reponse => {
        this.stations = reponse["stations"];
        this.listestations = reponse["listestations"];
        this.zones = reponse["zones"];
        this.petroliers = reponse["petroliers"];
        if (reponse !== null) {
          this.spinner.hide();
          // this.disabled = false;
        }
      })
      .catch(err => {
        console.log("Catch erreur : " + err);
      });
  }
  viewAll() {
    this.limit = this.tailleTableau(this.stations);
  }
  toggle() {
    this.show = !this.show;
  }
}
