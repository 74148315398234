import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AccueilComponent } from './accueil/accueil.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './auth/auth.guard';
import { DistanceComponent } from './distance/distance.component';
import { RayonComponent } from './rayon/rayon.component';
import { GeochartComponent } from './geochart/geochart.component';
import { CartesComponent } from './cartes/cartes.component';
import { DrawComponent } from './draw/draw.component';
import { SegmentationComponent } from './segmentation/segmentation.component';
import { LoginTokenComponent } from './login-token/login-token.component';
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'ui', loadChildren: './ui/ui.module#UiModule'},
  { path: 'auth', component: AuthComponent },
  { path: 'login/:token', component: LoginTokenComponent },
  { path: 'home',  component:AccueilComponent,
    children:[
              {path : 'distance' , component: DistanceComponent},
            ]
  },
  { path : 'rayon', component: RayonComponent},
  { path: 'home/segmentation', component: GeochartComponent},
  { path: 'home/cartes', component:CartesComponent},
  { path: 'draw', component: DrawComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
