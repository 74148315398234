import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MapService } from '../services/map.service';
import { CrudService } from '../services/crud.service';

declare const google: any;
declare const container: any;

@Component({
  selector: 'app-geochart',
  templateUrl: './geochart.component.html',
  styleUrls: ['./geochart.component.css']
})
export class GeochartComponent implements AfterViewInit, OnInit {
  mode = 1;
  myWidth = "100%";
  myEight = "100%";
  myType = "GeoChart";
  stations: any;
  petroliers: any;
  totalStations = 0;
  petro = [];
  elt = []
  zones: any;
  regions: any = this.crudService.regions;
  secteurs: any = this.crudService.secteurs;
  myData1;
  test = false
  options = {
    region: 'SN', // Africa
    resolution: 'provinces',
    colorAxis: {
      colors: ["#aaa", this.regions[0].color, this.regions[1].color, this.regions[2].color],
      values: [0, 1, 2, 3]
    },
    backgroundColor: '#ffffff',
    datalessRegionColor: '#ffffff',
    defaultColor: '#f5f5f5',
    displayMode: 'region'
  };

  myData = [
    ['Country', 'Latitude'],
    // ['Senegal', 14.6937000],['Algeria', 36], ['Angola', -8],
  ];

  colors = this.mapService.colors;

  myOptions = {
    region: 'SN', // Africa
    resolution: 'provinces',
    colorAxis: {
      colors: ["#aaa", this.colors[0].code, this.colors[1].code, this.colors[2].code, this.colors[3].code,
        this.colors[4].code, this.colors[5].code, this.colors[6].code, this.colors[7].code, this.colors[8].code, this.colors[9].code,
        this.colors[10].code, this.colors[11].code, this.colors[12].code],
      values: [0,2,3,4,5,6,7,8,9,10,11,12,13,14]
    },
    backgroundColor: '#ffffff',
    datalessRegionColor: '#ffffff',
    defaultColor: '#f5f5f5',
    displayMode: 'region'
  };

  // vis = new google.charts.setOnLoadCallback(this.drawRegionsMap);
  // visualization = new google.visualization.GeoChart(container);
  constructor(private mapService: MapService, private crudService: CrudService) { }
  ngOnInit() {
    this.mapService.init().then(
      (res) => {
        this.stations = res['stations'];
        this.zones = this.stationsTotalOnly(res['zones']);

        this.ranger(this.stations);
      }
    )
  }
  ngAfterViewInit() {
    google.charts.load('current', {
      'packages': ['geochart', 'corechart'],
      'mapsApiKey': 'AIzaSyCaSfdQyOwQoWtaDwtL5AMOm3eA492dg9M'
    });
    google.charts.setOnLoadCallback(() => this.drawRegionsMap(this.options));
    // let visualization = new google.visualization.GeoChart(container);
    // google.charts.setOnLoadCallback(this.drawRegionsMap(this.options));
  }

  ranger(stations) {
    this.viderSecteurs();
    for (var n in stations) {
      let station = stations[n];
      if (station.petrolier_id == 1) {
        for (var s in this.secteurs) {
          if (this.secteurs[s].id == station.secteur_id) this.secteurs[s].stations.push(station);
        }
      }
    }

    for (var r in this.regions) {
      for (var s in this.secteurs) {
        if (this.secteurs[s].region_id == this.regions[r].id) this.regions[r].secteurs.push(this.secteurs[s]);
      }
    }
  }

  viderSecteurs() {
    for (var s in this.secteurs) {
      this.secteurs[s].stations = [];
    }
  }

  drawRegionsMap(options) {
    var tab = [];
    var title = ["Provinces", "ID région", "Station(s)"];
    tab.push(title);
    for (var i in this.zones) {
      var zone = this.zones[i];
      var stations = zone.stations;
      var secteur = null;
      var petroliers = [];
      if (stations.length > 0) {
        for (var n in stations) {
          var station = stations[n];
          if (station.secteur_id) {
            secteur = this.mapService.getEntityById(station.secteur_id, this.secteurs);
            break;
          }
        }

      }
      // console.log(this.zones);
      if (secteur) {
        var region = this.mapService.getEntityById(secteur.region_id, this.regions);
        var elt = [{ v: zone.code, f: zone.name }, secteur.region_id, this.getRegionNumberStations(region)];
        // this.elt.push({ id: zone.id, v: zone.code, f: zone.name , t_station: stations.length, t_petroliers: petroliers.length})
      }
      else elt = [{ v: zone.code, f: zone.name }, 0, 0];
      tab.push(elt);
    }

    var data = new google.visualization.arrayToDataTable(tab);


    var chart = new google.visualization.GeoChart(document.getElementById('geochart-colors'));
    chart.draw(data, options);
  }

  drawSecteursMap(options) {
    var tab = [];
    var title = ["Provinces", "ID secteurs", "Station(s)"];
    tab.push(title);
    for (var i in this.zones) {
      var zone = this.zones[i];
      var stations = zone.stations;
      var secteur = null;
      var secteurOfZone = [];
      var secteurIds = [];
      if (stations.length > 0) {
        for (var n in stations) {
          var station = stations[n];
          if (station.secteur_id) {
            if(secteurIds.includes(station.secteur_id) === false) {
              secteurIds.push(station.secteur_id);
              let temp = this.mapService.getEntityById(station.secteur_id, this.secteurs);
              secteurOfZone.push(temp);
            }
          }
        }

        if(secteurOfZone.length > 0) {
          var max = 0;
          for(var n in secteurOfZone) {
            let sec = secteurOfZone[n];
            if(sec.stations.length > max) {
              max = sec.stations.length;
              secteur = this.mapService.getEntityById(sec.id, this.secteurs);
            }
          }
        }
      }
      // console.log(this.zones);
      if (secteur) {
        var elt = [{ v: zone.code, f: zone.name }, secteur.id, secteur.stations.length];
        // this.elt.push({ id: zone.id, v: zone.code, f: zone.name , t_station: stations.length, t_petroliers: petroliers.length})
      }
      else elt = [{ v: zone.code, f: zone.name }, 0, 0];
      tab.push(elt);
    }

    var data = new google.visualization.arrayToDataTable(tab);


    var chart = new google.visualization.GeoChart(document.getElementById('geochart-colors'));
    chart.draw(data, options);
  }

  sum(numbers) {
    var sum = 0;
    for (var i = 0; i < numbers.length; i++) {
      sum += numbers[i]
    }
    return sum;
  }

  getRegionNumberStations(region: any) {
    var total = 0;
    if (region.secteurs.length == 0) return 0;

    for (var i in region.secteurs) {
      total += region.secteurs[i].stations.length;
    }
    return total;
  }

  stationsTotalOnly(zone_s) {
    var zones: any[] = [];
    this.totalStations = 0
    for (var z in zone_s) {
      let zone = zone_s[z];
      let stationTotal = [];
      if (zone.stations.length > 0) {
        for (var s in zone.stations) {
          let station = zone.stations[s];
          if (station.petrolier_id == 1) {
            stationTotal.push(station);
            this.totalStations++;
          }
        }
      }
      zone.stations = stationTotal;
      zones[zone.id] = zone;
    }

    return zones;
  }

  display() {
    if (this.mode == 1) this.drawRegionsMap(this.options);
    else this.drawSecteursMap(this.myOptions);
  }
}
