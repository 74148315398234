import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login-token',
  templateUrl: './login-token.component.html',
  styleUrls: ['./login-token.component.css']
})

@Injectable()
export class LoginTokenComponent implements OnInit {

  user: any = [];
  constructor(public activateRoute: ActivatedRoute,
              public spinner: NgxSpinnerService,
              public userService: UserService,
              public router: Router,
              public toastr: ToastrService) {


  }

  ngOnInit() {
    this.spinner.show();
    let token = null;
    this.activateRoute.params.subscribe(items => {
      token = items.token
    });

    this.userService.loginWithToken(token).
      then(res => {
        //console.log(res);
        if (res != null) {
          this.spinner.hide();
          this.router.navigate(['/home']);
          this.toastr.success("Authentification réussie", "Bienvenue");
        }
        else {
          this.spinner.hide();
          this.router.navigate(['/auth']);
          this.toastr.error("Votre jeton de connexion a expiré ou n'est pas valide !", "Token invalide")
        }
        this.spinner.hide();
        //console.log(res);
      }).catch(
        err => {
          this.toastr.error("Une erreur est survenue au moment de la requete.", "Echec de la requête")
          this.router.navigate(['/auth']);
          this.spinner.hide();
        }
      )

  }

}
