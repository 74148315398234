import { Component, OnInit } from '@angular/core';
import { MapService } from '../services/map.service';
import { CrudService } from '../services/crud.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-segmentation',
  templateUrl: './segmentation.component.html',
  styleUrls: ['./segmentation.component.css']
})
export class SegmentationComponent implements OnInit {
  zones: any[] = [];
  stations: any[] = [];
  selected: number = null;
  modifier: boolean = false;
  textModifier = "Modifer";
  secteurs = this.crudService.secteurs;
  regions = this.crudService.regions;
  totalStation: number = 0;
  colors = this.mapService.colors;
  constructor(private mapService: MapService,
    private crudService: CrudService,
    private userService: UserService, 
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService) { }
  ngOnInit() {
    if(this.userService.checkAuthorization() ==null){
      return this.router.navigate(['/auth'])
    }
    this.spinner.show();
    this.mapService.init().then(
      (res) => {
        let zonesRecu = res['zones'];
        this.stations = res['stations'];
        
        if(this.mapService.tailleTableau(zonesRecu) > 0) {
          this.zones = this.stationsTotalOnly(zonesRecu);
          this.ranger(this.stations);
          this.spinner.hide()
        }
      }
    )
  }

  toggleModifier() {
    this.modifier = !this.modifier;
    if(this.modifier) this.textModifier = "Ok, fermer";
    else this.textModifier = "Modifier";
  }

  taille(objet: any[]) {
   return this.mapService.tailleTableau(objet);
  }
  
  ngAfterViewInit() {

  }

  stationsTotalOnly(zone_s) {
    this.totalStation = 0;
    var zones = [];
    for(var z in zone_s) {
      let zone = zone_s[z];
      let stationTotal = [];
      if(zone.stations.length > 0) {
        for(var s in zone.stations) {
          let station = zone.stations[s];
          if(station.petrolier_id == 1) {
            stationTotal.push(station);
            this.totalStation++;
          }
        }
      }
      zone.stations = stationTotal;
      zones[zone.id] = zone;
    }

    return zones;
  }

  ranger(stations) {
    this.viderSecteurs();
    for(var n in stations) {
      let station = stations[n];
      if(station.petrolier_id == 1) {
        for(var s in this.secteurs) {
            if(this.secteurs[s].id == station.secteur_id) this.secteurs[s].stations.push(station);
        }
        for(var r in this.regions) {
          for(var s in this.secteurs) {
            if(this.secteurs[s].region_id == this.regions[r].id) this.regions[r].secteurs.push(station);
          }
        }
      }
    }
  }

  viderSecteurs() {
    for(var s in this.secteurs) {
        this.secteurs[s].stations = [];
    }
  }

  update() {
    this.spinner.show()
    var updateds = []
    for(var key in this.zones) {
      let item = {
        "id": key,
        "color": this.zones[key].color
      }
      updateds.push(item);
    }

    this.crudService.updateZones(updateds).then(
      (response) => {
        if(response !== null) {
          this.zones = response['zones'];
          localStorage.setItem('datas',JSON.stringify(response));
          this.toastr.success("Enregistrement réussi", "Succès");
          this.spinner.hide()
        } 
        else {
          this.spinner.hide()
          this.toastr.error("Enregistrement échoué", "Echec enregistrement");
        }
        this.toggleModifier()
      },
      (error) => { this.toastr.error("La requête a échoué", "Echec enregistrement"); this.spinner.hide() }
    ).catch(
      err => { this.toastr.error("La requête a échoué", "Echec enregistrement");this.spinner.hide() }
    )
  }

  survol(id: number) {
    this.selected = id
  }

  quitter(id: number) {
    this.selected = null
  }
}
