import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MapService } from '../services/map.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MapLoaderService } from '../services/map.loader.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
declare var google: any;
@Component({
  selector: 'app-cartes',
  templateUrl: './cartes.component.html',
  styleUrls: ['./cartes.component.css']
})
export class CartesComponent implements OnInit, AfterViewInit {
  map: any;
  url = this.mapService.url;
  cartes: any;
  active: number = null;
  carteActive: any = null;
  headers = new HttpHeaders({ "Content-type": "application/json" });
  constructor(private userService: UserService, private mapService: MapService,private spinner: NgxSpinnerService,private router: Router) { }

  ngOnInit() {
      if(this.userService.checkAuthorization() ==null){
        return this.router.navigate(['/auth'])
      }
      this.loadObjets();
    }
    navToDraw(){
      this.router.navigate(['/draw']);
    }
    ngAfterViewInit(){
      MapLoaderService.load().then(() => {
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: 14.2761, lng: -14.45105 },
          zoom: 8
        });
      });
    }
  loadObjets() {
    this.mapService.loadCacheObjets().then(
      (res) => {
        this.cartes = res;
        // console.log(res);
      },
      (err) => {
        console.log('Erreur de requete : ' + err);
      }
    )
  }
  loadObject(objet) {
    var val;
    switch (objet.typeobjet_id) {
      case 1: //cercle
        val = this.loadCircle(objet);
        break;
      case 2: //polygone
        val = this.loadPolygon(objet);
        break;
      case 3: //polyline
        val = this.loadPolyline(objet);
        break;
      case 4: //rectangle
        val = this.loadRectangle(objet);
        break;
      case 5: //MArqueur
      val = this.loadMarker(objet);
      break;
    }
    return val;
  }
  viewCard(carte) {
    this.active = carte.id;
    this.carteActive = carte;
    this.map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: carte.lat, lng: carte.lng },
      zoom: 7
    });
    var lists = [];
    for (var i = 0; i < carte.objets.length; i++) {
      var objet = carte.objets[i];
      lists.push(this.loadObject(objet));
    }
  }
  loadPolygon(objet) {
    var bermudaTriangle;
    var paths = JSON.parse(objet.paths);
    // this.drawingManager.setMap(null);
    bermudaTriangle = new google.maps.Polygon({
      paths: paths,
      strokeColor: "#FFF",
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: "#17a2b8",
      fillOpacity: 0.35,
      map: this.map
    });
    this.info(objet, bermudaTriangle);
    this.infover(objet, bermudaTriangle);
    this.infomove(objet,bermudaTriangle);
    return bermudaTriangle;
  }
  loadMarker(objet) {
    var bermudaTriangle;
    var center = JSON.parse(objet.center);
    // this.drawingManager.setMap(null);
    bermudaTriangle = new google.maps.Marker({
      position: center,
      icon: objet.icon,
      map: this.map
    });
    this.info(objet, bermudaTriangle);
    this.infover(objet, bermudaTriangle);
    this.infomove(objet,bermudaTriangle);
    return bermudaTriangle;
  }

  loadRectangle(objet) {
    var bermudaTriangle;
    var bound = JSON.parse(objet.bounds);
    var bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(bound.j.j, bound.j.l),
      new google.maps.LatLng(bound.l.j, bound.l.l)
    );
    bermudaTriangle = new google.maps.Rectangle({
      bounds: bounds,
      strokeColor: "#FFF",
      strokeOpacity: 1,
      center: JSON.parse(objet.center),
      strokeWeight: 2,
      fillColor: "#28a745",
      fillOpacity: 0.35,
      map: this.map
    });
    this.info(objet, bermudaTriangle);
    this.infover(objet, bermudaTriangle);
    this.infomove(objet, bermudaTriangle);
    return bermudaTriangle;
  }
  loadPolyline(objet) {
    var bermudaTriangle;
    var paths = JSON.parse(objet.paths);
    // this.drawingManager.setMap(null);
    bermudaTriangle = new google.maps.Polyline({
      path: paths,
      strokeColor: "#6c757d",
      strokeOpacity: 1,
      strokeWeight: 3,
      icons: [{ icon: "assets/icons/icon-total.png" }],
      map: this.map
    });
    this.info(objet, bermudaTriangle);
    this.infover(objet, bermudaTriangle);
    this.infomove(objet, bermudaTriangle);
    return bermudaTriangle;
  }
  loadCircle(objet) {
    var bermudaTriangle;
    var radius = objet.radius;
    var center = JSON.parse(objet.center);
    bermudaTriangle = new google.maps.Circle({
      radius: radius,
      strokeColor: "#FFF",
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: "#dc3545",
      center: center,
      fillOpacity: 0.35,
      map: this.map
    });
    this.info(objet, bermudaTriangle);
    // this.infover(objet, bermudaTriangle);
    // this.infomove(objet, bermudaTriangle);
    return bermudaTriangle;
  }

  info(objet,overlay){
    google.maps.event.addListener(overlay, "click", function (event) {
      var infowindow = new google.maps.InfoWindow({
        // size: new google.maps.Size(150, 50)
      });
      let comment = objet.commentaire;
      if (comment == null) {
        comment = "Aucun commentaire laissé";
      }
      var contentString =
        '<ul class="list-group">' +
        '<li style="font-size:14px;font-weight: 400" class="bg-primary list-group-item text-center text-white">' +objet.name+
        '</li>' +
        '<li class="bg-light text-center list-group-item">' +
          comment +
        '</li>' +
        '</ul>';
      infowindow.setContent(contentString);
      infowindow.setPosition(event.latLng);
      infowindow.open(this.map);
    });
  }

  infover(objet,overlay){
    google.maps.event.addListener(overlay, "mouseover", function (event) {
      var infowindow = new google.maps.InfoWindow({
        size: new google.maps.Size(150, 50)
      });
      let comment = objet.name;
      if (comment == null) {
        comment = "Aucun commentaire laissé";
      }
      var contentString =
        '<div class="card" style="padding:10px">' +
        comment +
        "</div>";
      infowindow.setContent(contentString);
      infowindow.setPosition(event.latLng);
    });
  }

  infomove(objet,overlay){
    google.maps.event.addListener(overlay, "mouseout", function (event) {
      var infowindow = new google.maps.InfoWindow({
        size: new google.maps.Size(150, 50)
      });
      let comment = objet.commentaire;
      if (comment == null) {
        comment = "Aucun commentaire laissé";
      }
      var contentString =
        '<div class="card" style="padding:10px">' +
        comment +
        "</div>";
      infowindow.setContent(contentString);
      infowindow.setPosition(event.latLng);
      infowindow.close();
    });
  }
}
