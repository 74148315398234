import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class CrudService {
  public url =  "https://api.total-malaw.com/api/";
  // public url = "http://localhost:8765/api/";
  public headers = new HttpHeaders({ "content-type": "application/json" });
  public station = {
    id: null,
    name: "",
    adresse: "",
    lng: null,
    lat: null,
    zone_id: null,
    petrolier_id: null,
    type_id: "1",
    secteur_id: null,
    gestion_id: null,
    etat_id: "1",
    ouverture: null,
    fermeture: null,
    gerantmail: null,
    phone: null,
    gerant: null,
    surete_id: null,
  };
  secteurs = [
    {
      id: 2,
      name: "DK1",
      region_id: 1,
      stations: [],
      chef: "ABDOULAYE FALL",
    },
    {
      id: 3,
      name: "DK2",
      region_id: 1,
      stations: [],
      chef: "CODOU AICHA DIOUF",
    },
    {
      id: 4,
      name: "DK3",
      region_id: 1,
      stations: [],
      chef: "MANSOUR DIENG",
    },
    {
      id: 5,
      name: "DK4",
      region_id: 1,
      stations: [],
      chef: "BANDEL DIA",
    },
    {
      id: 6,
      name: "CS1",
      region_id: 2,
      stations: [],
      chef: "HABIB MANE",
    },
    {
      id: 7,
      name: "CS2",
      region_id: 2,
      stations: [],
      chef: "AMADY DIOP",
    },
    {
      id: 8,
      name: "CS3",
      region_id: 2,
      stations: [],
      chef: "EL HADJ CAMARA",
    },
    {
      id: 9,
      name: "CS4",
      region_id: 2,
      stations: [],
      chef: "PENDA SOW",
    },
    {
      id: 10,
      name: "CN1",
      region_id: 3,
      stations: [],
      chef: "IBRAHIMA NDIAYE",
    },
    {
      id: 11,
      name: "CN2",
      region_id: 3,
      stations: [],
      chef: "SERIGNE SALIOU DIONE",
    },
    {
      id: 12,
      name: "CN3",
      region_id: 3,
      stations: [],
      chef: "BINTA FOSTER",
    },
    {
      id: 13,
      name: "CN4",
      region_id: 3,
      stations: [],
      chef: "ALY MAMOUR JACQUES FAYE",
    },
    {
      id: 14,
      name: "CN5",
      region_id: 3,
      stations: [],
      chef: "SEYNABOU LAWAL",
    }
  ];

  regions = [
    {
      id: 1,
      name: "DAKAR",
      chef: "CHEIKH BARA NIANG",
      color: "#0096ff",
      secteurs: []
    },
    {
      id: 2,
      name: "CENTRE SUD",
      chef: "OUMAR GUEYE",
      color: "#E3123D",
      secteurs: []
    },
    {
      id: 3,
      name: "CENTRE NORD",
      chef: "OUSMANE LO SARR",
      color: "#FFD700",
      secteurs: []
    }
  ];
  gestions = [
    {
      id: 1,
      name: "CODO CONTROLE"
    },
    {
      id: 2,
      name: "CODO FINANCIER"
    },
    {
      id: 3,
      name: "DODO"
    },
    {
      id: 4,
      name: "COCO"
    }
  ];
  types = [
    {
      id: 1,
      name: "Terrestre"
    },
    {
      id: 2,
      name: "Pêche"
    }
  ];
  constructor(private httpClient: HttpClient) {}

  addUser(user: any) {
    var body = JSON.stringify(user);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "users/add.json", body, { headers: this.headers })
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  editUser(user: any) {
    var body = JSON.stringify(user);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "users/edit/" + user.id + ".json", body, {
          headers: this.headers
        })
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  deleteUser(userId: any) {
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "users/supprimer/" + userId + ".json", {
          headers: this.headers
        })
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  addPetrolier(petrolier: any) {
    var body = JSON.stringify(petrolier);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "petroliers/add.json", body, { headers: this.headers })
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  editPetrolier(petrolier: any) {
    var body = JSON.stringify(petrolier);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "petroliers/edit/" + petrolier.id + ".json", body, {
          headers: this.headers
        })
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  viewPetrolier(petrolier: any) {
    var body = JSON.stringify(petrolier);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "petroliers/view/" + petrolier.id + ".json", body, {
          headers: this.headers
        })
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  deletePetrolier(petrolierId: any) {
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "petroliers/supprimer/" + petrolierId + ".json", {
          headers: this.headers
        })
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  getEntityById(id: number, array: any) {
    const entity = array.find(s => {
      return s.id === id;
    });
    return entity;
  }

  getUsers() {
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .get(this.url + "users/index.json")
        .toPromise()
        .then(res => resolve(res), err => reject(err));
    });
    return promise;
  }

  addStation(station) {
    var body = JSON.stringify(station);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "stations/add/.json", body, { headers: this.headers })
        .toPromise()
        .then(res => resolve(res), error => reject(error));
    });
    return promise;
  }

  updateZones(zones) {
    var body = JSON.stringify(zones);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "stations/updatezones.json", body, { headers: this.headers })
        .toPromise()
        .then(res => resolve(res), error => reject(error));
    });
    return promise;
  }

  save(stations) {
    var body = JSON.stringify(stations);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "stations/save.json", body, { headers: this.headers })
        .toPromise()
        .then(res => resolve(res), error => reject(error));
    });
    return promise;
  }

  editStation(station) {
    var body = JSON.stringify(station);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "stations/edit/" + station.id + ".json", body, {
          headers: this.headers
        })
        .toPromise()
        .then(res => resolve(res), err => reject(err));
    });
    return promise;
  }

  deleteStation(id) {
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "stations/supprimer/" + id + ".json", {
          headers: this.headers
        })
        .toPromise()
        .then(res => resolve(res), err => reject(err));
    });
    return promise;
  }
}
