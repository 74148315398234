import { Component, OnInit } from '@angular/core';
import { AccueilComponent } from "../accueil/accueil.component";
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { MapService } from '../services/map.service';
import { NgxSpinnerService } from 'ngx-spinner';
declare var google: any;

@Component({
  selector: 'app-distance',
  templateUrl: './distance.component.html',
  styleUrls: ['./distance.component.css']
})
export class DistanceComponent implements OnInit {
  public fermer: boolean = true;
  datas :any = [];
  listestations = [];
  adress1: any;
  adress2: any;
  distance:number;
  marker1:any;
  marker2 : any;
  public test :boolean=false;
  constructor(public f:AccueilComponent,private router: Router,private userService : UserService,
    private mapService: MapService, private spinner: NgxSpinnerService) {
   }

  ngOnInit() {
    if(this.userService.checkAuthorization() ==null){
      return this.router.navigate(['/auth'])
    }
    this.chargerDatas();
  }
  close() {
    this.router.navigate(['/home']);
  }
  check(){
    this.spinner.show();
    var bounds = new google.maps.LatLngBounds();
    this.test = false;
    let val1 = this.userService.getEntityById(this.adress1, this.listestations);
    let val2 = this.userService.getEntityById(this.adress2,this.listestations);
    let rslt = this.mapService.getDistance(val1,val2);
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
      this.test = !this.test;
      
    }, 3000);
    this.distance = rslt;
    let paths = []
    let p1 = { lat: val1.lat, lng: val1.lng }
    let p2 = {lat:val2.lat, lng:val2.lng}
    paths.push(p1,p2);
    // console.log(val1);
    this.f.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 7,
      center: p2
    });

    var polyline;
    polyline = new google.maps.Polyline({
      path: paths,
      geodesic: true,
      strokeColor: '#007bff',
      strokeOpacity: 1.0,
      strockeWeight: 70
    });
    
    this.marker1 = new google.maps.Marker({
      position: p1,
      map: this.f.map,
      title: val1.name,
      icon: 'assets/icons/'+val1.petrolier.icon

    });
    bounds.extend(new google.maps.LatLng(val1.lat, val1.lng));
    this.marker2 = new google.maps.Marker({
      position: p2,
      map: this.f.map,
      title: val2.name,
      icon: 'assets/icons/'+val2.petrolier.icon
    });
    bounds.extend(new google.maps.LatLng(val2.lat, val2.lng));
    this.infowindow(val1);
    this.infowindow2(val2);
    polyline.setMap(this.f.map);
    this.f.map.fitBounds(bounds);
  }
  infowindow(val1){
    var infowindow = new google.maps.InfoWindow();

    google.maps.event.addListener(
      this.marker1,
      "click",
      (function (marker, content, infowindow) {
        return function () {
          infowindow.setContent(content);
          infowindow.open(this.map, marker);
        };
      })(this.marker1, this.mapService.info2window(val1,val1.petrolier_id), infowindow)
    );
    // google.maps.event.addListener(
    //   this.marker2,
    //   "click",
    //   (function (marker, content, infowindow) {
    //     return function () {
    //       infowindow.setContent(content);
    //       infowindow.open(this.map, marker);
    //     };
    //   })(this.marker1, this.mapService.info2window(val2,val2.petrolier_id), infowindow)
    // );
  }
  infowindow2(val2) {
    var infowindow = new google.maps.InfoWindow();

    google.maps.event.addListener(
      this.marker2,
      "click",
      (function (marker, content, infowindow) {
        return function () {
          infowindow.setContent(content);
          infowindow.open(this.map, marker);
        };
      })(this.marker2, this.mapService.info2window(val2, val2.petrolier_id), infowindow)
    );
    // google.maps.event.addListener(
    //   this.marker2,
    //   "click",
    //   (function (marker, content, infowindow) {
    //     return function () {
    //       infowindow.setContent(content);
    //       infowindow.open(this.map, marker);
    //     };
    //   })(this.marker1, this.mapService.info2window(val2,val2.petrolier_id), infowindow)
    // );
  }
  chargerDatas() {
    this.spinner.show()
    this.mapService
      .init()
      .then(reponse => {
        this.listestations = reponse["listestations"];
        if (reponse !== null) {
          this.spinner.hide();
          // this.disabled = false;
        }
      })
      .catch(err => {
        console.log("Catch erreur : " + err);
      });
  }
}
