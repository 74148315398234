import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})

@Injectable()
export class AuthComponent implements OnInit {
  user: any = [];
  constructor(public router: Router, public spinner: NgxSpinnerService,public userService: UserService,public toastr: ToastrService) { }

  // tslint:disable-next-line: contextual-lifecycle
  ngOnInit() {
    this.initialize();
    if (this.userService.checkAuthorization() !== null) {
      this.router.navigate(['/auth']);
    }
  }
  initialize() {
    this.user = {
      email: null,
      password: null
    }
  }
  login() {
    console.log(this.user);
    this.spinner.show();
    this.userService.logincake(this.user).
      then(res => {

        if (res != null) {
          this.spinner.hide();
          this.router.navigate(['/home']);
          this.toastr.success("Authentification réussie", "Bienvenue");
        }
        else {
          this.spinner.hide();
          this.toastr.error("Vous ne pouvez y accèder !", "Email ou mot de passe incorrect")
        }
        this.spinner.hide();
        //console.log(res);
      }).catch(
        err => {
          this.toastr.error("Vous ne pouvez y accèder !", "Echec de la requête")
          this.spinner.hide();
        }
      )
  }
}
