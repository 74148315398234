import { Injectable, TemplateRef } from '@angular/core';
import { MapLoaderService } from './map.loader.service';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import * as MarkerClusterer from "@google/markerclusterer";

declare var google: any;
declare const MarkerClusterer: any;

@Injectable()
export class MapService {
  markerSubject = new Subject<any>();
  stationSubject =  new Subject<any>();
  public modalRef: BsModalRef;
  public centerLat = 14.2761;
  public centerLng = -14.45105;
  colors = [
    {'code': "#0096ff"},
    {'code': "#ff0000"},
    {'code': "#63b598"},
    {'code': "#ce7d78"},
    {'code': "#ea9e70"},
    {'code': "#a48a9e"},
    {'code': "#c6e1e8"},
    {'code': "#648177"},
    {'code': "#f205e6"},
    {'code': "#1c0365"},
    {'code': "#14a9ad"},
    {'code': "#4ca2f9"},
    {'code': "#a4e43f"},
    {'code': "#d298e2"},
    {'code': "#6119d0"},
    {'code': "#d2737d"},
    {'code': "#c0a43c"},
    {'code': "#f2510e"},
    {'code': "#651be6"},
    {'code': "#79806e"},
    {'code': "#61da5e"},
    {'code': "#cd2f00"},
    {'code': "#9348af"},
    {'code': "#01ac53"},
    {'code': "#c5a4fb"},
    {'code': "#996635"},
    {'code': "#b11573"},
    {'code': "#4bb473"},
    {'code': "#75d89e"},
    {'code': "#2f3f94"},
    {'code': "#2f7b99"},
    {'code': "#da967d"},
    {'code': "#34891f"},
    {'code': "#b0d87b"},
    {'code': "#ca4751"},
    {'code': "#7e50a8"},
    {'code': "#c4d647"},
    {'code': "#e0eeb8"},
    {'code': "#11dec1"},
    {'code': "#289812"},
    {'code': "#566ca0"},
    {'code': "#ffdbe1"},
    {'code': "#2f1179"},
    {'code': "#935b6d"},
    {'code': "#916988"},
    {'code': "#513d98"},
    {'code': "#aead3a"},
    {'code': "#9e6d71"},
    {'code': "#4b5bdc"},
    {'code': "#0cd36d"},
    {'code': "#250662"},
    {'code': "#cb5bea"},
    {'code': "#228916"},
    {'code': "#ac3e1b"},
    {'code': "#df514a"},
    {'code': "#539397"},
    {'code': "#880977"},
    {'code': "#f697c1"},
    {'code': "#ba96ce"},
    {'code': "#679c9d"},
    {'code': "#c6c42c"},
    {'code': "#5d2c52"},
    {'code': "#48b41b"},
    {'code': "#e1cf3b"},
    {'code': "#5be4f0"},
    {'code': "#57c4d8"},
    {'code': "#a4d17a"},
    {'code': "#225b8"},
    {'code': "#be608b"},
    {'code': "#96b00c"},
    {'code': "#088baf"},
    {'code': "#f158bf"},
    {'code': "#e145ba"},
    {'code': "#ee91e3"},
    {'code': "#05d371"},
    {'code': "#5426e0"},
    {'code': "#4834d0"},
    {'code': "#802234"},
    {'code': "#6749e8"},
    {'code': "#0971f0"},
    {'code': "#8fb413"},
    {'code': "#b2b4f0"},
    {'code': "#c3c89d"},
    {'code': "#c9a941"},
    {'code': "#41d158"},
    {'code': "#fb21a3"},
    {'code': "#51aed9"},
    {'code': "#5bb32d"},
    {'code': "#807fb"},
    {'code': "#21538e"},
    {'code': "#89d534"},
    {'code': "#d36647"},
    {'code': "#7fb411"},
    {'code': "#0023b8"},
    {'code': "#3b8c2a"},
    {'code': "#986b53"},
    {'code': "#f50422"},
    {'code': "#983f7a"},
    {'code': "#ea24a3"},
    {'code': "#79352c"},
    {'code': "#521250"},
    {'code': "#c79ed2"},
    {'code': "#d6dd92"},
    {'code': "#e33e52"},
    {'code': "#b2be57"},
    {'code': "#fa06ec"},
    {'code': "#1bb699"},
    {'code': "#6b2e5f"},
    {'code': "#64820f"},
    {'code': "#1c271"},
    {'code': "#21538e"},
    {'code': "#89d534"},
    {'code': "#d36647"},
    {'code': "#7fb411"},
    {'code': "#0023b8"},
    {'code': "#3b8c2a"},
    {'code': "#986b53"},
    {'code': "#f50422"},
    {'code': "#983f7a"},
    {'code': "#ea24a3"},
    {'code': "#79352c"},
    {'code': "#521250"},
    {'code': "#c79ed2"},
    {'code': "#d6dd92"},
    {'code': "#e33e52"},
    {'code': "#b2be57"},
    {'code': "#fa06ec"},
    {'code': "#1bb699"},
    {'code': "#6b2e5f"},
    {'code': "#64820f"},
    {'code': "#1c271"},
    {'code': "#9cb64a"},
    {'code': "#996c48"},
    {'code': "#9ab9b7"},
    {'code': "#06e052"},
    {'code': "#e3a481"},
    {'code': "#0eb621"},
    {'code': "#fc458e"},
    {'code': "#b2db15"},
    {'code': "#aa226d"},
    {'code': "#792ed8"},
    {'code': "#73872a"},
    {'code': "#520d3a"},
    {'code': "#cefcb8"},
    {'code': "#a5b3d9"},
    {'code': "#7d1d85"},
    {'code': "#c4fd57"},
    {'code': "#f1ae16"},
    {'code': "#8fe22a"},
    {'code': "#ef6e3c"},
    {'code': "#243eeb"},
    {'code': "#1dc18"},
    {'code': "#dd93fd"},
    {'code': "#3f8473"},
    {'code': "#e7dbce"},
    {'code': "#421f79"},
    {'code': "#7a3d93"},
    {'code': "#635f6d"},
    {'code': "#93f2d7"},
    {'code': "#9b5c2a"},
    {'code': "#15b9ee"},
    {'code': "#0f5997"},
    {'code': "#409188"},
    {'code': "#911e20"},
    {'code': "#1350ce"},
    {'code': "#10e5b1"},
    {'code': "#fff4d7"},
    {'code': "#cb2582"},
    {'code': "#ce00be"},
    {'code': "#32d5d6"},
    {'code': "#17232"},
    {'code': "#608572"},
    {'code': "#c79bc2"},
    {'code': "#00f87c"},
    {'code': "#77772a"},
    {'code': "#6995ba"},
    {'code': "#fc6b57"},
    {'code': "#f07815"},
    {'code': "#8fd883"},
    {'code': "#060e27"},
    {'code': "#96e591"},
    {'code': "#21d52e"},
    {'code': "#d00043"},
    {'code': "#b47162"},
    {'code': "#1ec227"},
    {'code': "#4f0f6f"},
    {'code': "#1d1d58"},
    {'code': "#947002"},
    {'code': "#bde052"},
    {'code': "#e08c56"},
    {'code': "#28fcfd"},
    {'code': "#bb09b"},
    {'code': "#36486a"},
    {'code': "#d02e29"},
    {'code': "#1ae6db"},
    {'code': "#3e464c"},
    {'code': "#a84a8f"},
    {'code': "#911e7e"},
    {'code': "#3f16d9"},
    {'code': "#0f525f"},
    {'code': "#ac7c0a"},
    {'code': "#b4c086"},
    {'code': "#c9d730"},
    {'code': "#30cc49"},
    {'code': "#3d6751"},
    {'code': "#fb4c03"},
    {'code': "#640fc1"},
    {'code': "#62c03e"},
    {'code': "#d3493a"},
    {'code': "#88aa0b"},
    {'code': "#406df9"},
    {'code': "#615af0"},
    {'code': "#4be47"},
    {'code': "#2a3434"},
    {'code': "#4a543f"},
    {'code': "#79bca0"},
    {'code': "#a8b8d4"},
    {'code': "#00efd4"},
    {'code': "#7ad236"},
    {'code': "#7260d8"},
    {'code': "#1deaa7"},
    {'code': "#06f43a"},
    {'code': "#823c59"},
    {'code': "#e3d94c"},
    {'code': "#dc1c06"},
    {'code': "#f53b2a"},
    {'code': "#b46238"},
    {'code': "#2dfff6"},
    {'code': "#a82b89"},
    {'code': "#1a8011"},
    {'code': "#436a9f"},
    {'code': "#1a806a"},
    {'code': "#4cf09d"},
    {'code': "#c188a2"},
    {'code': "#67eb4b"},
    {'code': "#b308d3"},
    {'code': "#fc7e41"},
    {'code': "#af3101"},
    {'code': "#ff065"},
    {'code': "#71b1f4"},
    {'code': "#a2f8a5"},
    {'code': "#e23dd0"},
    {'code': "#d3486d"},
    {'code': "#00f7f9"},
    {'code': "#474893"},
    {'code': "#3cec35"},
    {'code': "#1c65cb"},
    {'code': "#5d1d0c"},
    {'code': "#2d7d2a"},
    {'code': "#ff3420"},
    {'code': "#5cdd87"},
    {'code': "#a259a4"},
    {'code': "#e4ac44"},
    {'code': "#1bede6"},
    {'code': "#8798a4"},
    {'code': "#d7790f"},
    {'code': "#b2c24f"},
    {'code': "#de73c2"},
    {'code': "#d70a9c"},
    {'code': "#25b67"},
    {'code': "#88e9b8"},
    {'code': "#c2b0e2"},
    {'code': "#86e98f"},
    {'code': "#ae90e2"},
    {'code': "#1a806b"},
    {'code': "#436a9e"},
    {'code': "#0ec0ff"},
    {'code': "#f812b3"},
    {'code': "#b17fc9"},
    {'code': "#8d6c2f"},
    {'code': "#d3277a"},
    {'code': "#2ca1ae"},
    {'code': "#9685eb"},
    {'code': "#8a96c6"},
    {'code': "#dba2e6"},
    {'code': "#76fc1b"},
    {'code': "#608fa4"},
    {'code': "#20f6ba"},
    {'code': "#07d7f6"},
    {'code': "#dce77a"},
    {'code': "#77ecca"}
  ];
  public url =  "https://api.total-malaw.com/api/stations/";
  // public url = "http://localhost:8765/api/stations/";
  public headers = new HttpHeaders();
  constructor(
    private httpClient: HttpClient,
    private modalService: BsModalService
  ) {
    this.headers.append("content-type", "application/json" )
  }

  init() {
    if(localStorage.getItem('datas') == null){
      return this.loadDatas();
    } else{
      this.httpClient.get(this.url + "index.json",{headers:this.headers}).subscribe(
        (res) => { localStorage.setItem('datas',JSON.stringify(res));}
      )
      let promise = new Promise((resolve, reject) => {
        resolve(JSON.parse(localStorage.getItem('datas')));
      });
      return promise;
    }
  }

  loadCacheObjets() {
    if(localStorage.getItem('objets') == null){
      return this.loadObjets();
    } else{
      this.httpClient.get(this.url + "listcartes.json").subscribe(
        (res) => {localStorage.setItem('objets',JSON.stringify(res));}
      )
      let promise = new Promise((resolve, reject) => {
        resolve(JSON.parse(localStorage.getItem('objets')));
      });
      return promise;
    }
  }

  trierObjet(objet) {
    var filtrable: any[] = [];
    for(var i in objet) {
      filtrable.push(objet[i]);
    }

    filtrable.sort(function(a,b) {
      return b.stations.length - a.stations.length;
    });
    return filtrable;
  }

  tailleTableau(obj){
    var size = 0,key;
    for(key in obj){
      if(obj.hasOwnProperty(key)){
        size++;
      }
    }
    return size;
  }

  loadObjets() {
    let promise = new Promise((resolve, reject) => {
      this.httpClient.get(this.url + "listcartes.json",{headers: this.headers}).toPromise().then(
        res =>{
          localStorage.setItem("objets", JSON.stringify(res));
          resolve(res);
        } ,
        err=> reject(err)
      );
    });
    return promise;
  }

  loadDatas(){
    let promise = new Promise((resolve, reject) => {
        this.httpClient.get(this.url + "index.json",{headers:this.headers}).toPromise().then(
          res => { resolve(res); localStorage.setItem('datas',JSON.stringify(res)); },
          error => reject(error)
        );
      });
    return promise;
  }

  emitmarkerSuject(markers: any) {
    this.markerSubject.next(markers.slice());
  }
  emitstationSuject(stations: any) {
    this.stationSubject.next(stations);
  }
  ngAfterViewInit(map) {
    MapLoaderService.load().then(() => {
      map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 14.2761, lng: -14.45105 },
        zoom: 8
      });
    });
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template); // {3}
  }
  loadMarkers(
    stations,
    zones,
    petroliers,
    idType: number,
    type: string,
    action: string,
    auto: boolean= true
  ) {
    if (type == "petrolier") {
      var stationspetro = petroliers[idType].stations;
      if (action == "remplir") {
        for (var i in stationspetro) {
          var marker = stations[stationspetro[i].id];
          marker.check = true;
          if(auto) zones[marker.zone_id].check = true;
        }
      } else if (action == "vider") {
        for (var i in stationspetro) {
          var marker = stations[stationspetro[i].id];
          marker.check = false;
          if(auto) zones[marker.zone_id].check = false;
        }
      }
    } else if (type == "zone") {
      var stationszone = zones[idType].stations;
      if (action == "remplir") {
        for (var i in stationszone) {
          var marker = stations[stationszone[i].id];
          marker.check = true;
          if(auto) petroliers[marker.petrolier_id].check = true;
          // markers[stations[i].id] = marker;
        }
      } else if (action == "vider") {
        for (var i in stationszone) {
          var marker = stations[stationszone[i].id];
          marker.check = false;
          if(auto) petroliers[marker.petrolier_id].check = false;
          // delete markers[stations[i].id];
        }
      }
    } else {
      return false;
    }
  }

  filtrage(stations, markers, zones, petroliers) {
    for (var i in stations) {
      var station = stations[i];
      var zone = zones[station.zone_id];
      var petrolier = petroliers[station.petrolier_id];
      if (
        station.check == true &&
        petrolier.check == true &&
        zone.check == true
      ) {
        if (markers.includes(station) == false) {
          markers.push(station);
          station.check = true;
        }
      } else {
        if (markers.includes(station) == true) {
          //On enleve si l'eleMent n'est pas coché
          station.check = false;
          this.remove(markers, station);
        }
      }
    }
    this.emitmarkerSuject(markers);
  }

  nbrEntites(zones, petroliers, nom: string) {
    var nbr = 0;
    if (nom == "petrolier") {
      for (var i in petroliers) {
        if (petroliers[i].check == true) {
          nbr++;
        }
      }
    } else if (nom == "zone") {
      for (var i in zones) {
        if (zones[i].check == true) {
          nbr++;
        }
      }
    } else {
      return false;
    }
    return nbr;
  }

  getEntityById(id: number, entities: any) {
    const entity = entities.find(s => {
      return s.id === id;
    });
    return entity;
  }

  recentrer(stations,zones,petroliers, map) {
    let canCenter = true;
    for(var i in stations) {
      var station = stations[i];
      var zone = zones[station.zone_id];
      var petrolier = petroliers[station.petrolier_id];
      if (station.check == true &&
        petrolier.check == true &&
        zone.check == true) {
        canCenter = false;
        break;
      }
    }
    if (canCenter) {
      return (map = new google.maps.Map(document.getElementById("map"), {
        zoom: 8,
        center: {
          lat: this.centerLat,
          lng: this.centerLng
        }
      }));
    }
  }

  creationMarkeurs(stations, markers, map, clusterActive: boolean, surete : boolean = false) {
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 14.6937, lng: -17.44406 },
      zoom: 8
    });

    var bounds = new google.maps.LatLngBounds();
    var markerCrees = [];

    for (var i in markers) {
      var id = markers[i].id;
      stations[id].check = true;
      var station = stations[id];
      var marker;
      // var image;
      var petroId = stations[id].petrolier_id;
      // var urlImage = this.checkIcon(petroId);
      var urlImage = "assets/icons/" + stations[id].petrolier.icon;
      if(stations[id].petrolier.surete && surete){
        if(stations[id].surete_id ==  1) var urlImage = "assets/icons/map_point_vert.svg";
        if(stations[id].surete_id ==  2) var urlImage = "assets/icons/map_point_orange.svg";
        if(stations[id].surete_id ==  3) var urlImage = "assets/icons/map_point_rouge.svg";
        if(stations[id].surete_id ==  4) var urlImage = "assets/icons/map_point_orange_2.svg";
      }
      var icon = {
          url: urlImage, // url
          scaledSize: new google.maps.Size(50, 50), // scaled size
          origin: new google.maps.Point(0,0), // origin
          anchor: new google.maps.Point(0, 0) // anchor
      };
      var myLatLng = new google.maps.LatLng(station.lat, station.lng);
      marker = new google.maps.Marker({
        position: { lat: station.lat, lng: station.lng },
        map: map,
        title: station.name,
        icon: icon
      });
      var activeInfoWindow;
      var infowindow = new google.maps.InfoWindow();
      google.maps.event.addListener(
        marker,
        "click",
        (function(marker, content, infowindow) {
          return function() {
            if(activeInfoWindow){ activeInfoWindow.close(); }
            infowindow.setContent(content);
            infowindow.open(this.map, marker);
            activeInfoWindow = infowindow;
          };
        })(marker, this.info2window(station,station.petrolier_id), infowindow)
      );

      markerCrees.push(marker);
      bounds.extend(myLatLng);
    }
    if (clusterActive == true){
      // Add a marker clusterer to manage the markers.
      var cluster = new MarkerClusterer(map, markerCrees, {
        imagePath:
          "http://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
      });
    }
    return map.fitBounds(bounds);
  }

  checkAll(stations, zones, petroliers, type:any = false) {
    for (var i in stations) {
      var temp = stations[i];
      if(type) {
        if(type == "petrolier" && petroliers[temp.petrolier_id]) petroliers[temp.petrolier_id].check = true;
        if(type == "zone" && zones[temp.zone_id]) zones[temp.zone_id].check = true;
      }
      else {
        temp.check = true;
        if(zones[temp.zone_id]) zones[temp.zone_id].check = true;
        if(petroliers[temp.petrolier_id]) petroliers[temp.petrolier_id].check = true;
      }
    }
  }

  hideAll(stations, zones, petroliers, type:any = false) {
    for (var i in stations) {
      var temp = stations[i];
      if(type) {
        if(type == "petrolier") petroliers[temp.petrolier_id].check = false;
        if(type == "zone") zones[temp.zone_id].check = false;
      }
      else {
          temp.check = false;
          zones[temp.zone_id].check = false;
          petroliers[temp.petrolier_id].check = false;
      }
    }
  }

  remove(array: any, item: any) {
    var i = array.indexOf(item);
    if (i > -1) {
      array.splice(i, 1);
    }
  }

  info2window(station, petrolierId) {
    if(petrolierId == 1){
    var contentString =
      "<div>" +
      '<p style="font-size:14px;font-weight: 400">' +
      station.name +
      "</p>" +
      '<span style="color:#ddd;font-size:11px">Adresse' +
      "</span><br>" +
      "<span>" +
      station.adresse +
      "</span><br>" +
      '<span style="color:#ddd;font-size:11px">Horaires</span><br>' +
      "<span>Ouvre : " +
      station.ouverture +
      " <br>Ferme : " +
      station.fermeture +
      "</span><br>" +
      '<span style="color:#ddd;font-size:11px">Telephone</span><br>' +
      "<span>" +
      station.phone +
      "</span> <br>" +
      '<span style="color:#ddd;font-size:11px">Email</span><br>' +
      "<span>" +
      station.gerantmail +
      "</span><br>" +
      '<span style="color:#ddd;font-size:11px">Coordonnées</span><br>' +
      "<span>lat:" +
      station.lat +
      ", long:" +
      station.lng +
      "</span><br>" +
      "</div>";
    } else{
      var contentString =
        "<div>" +
        '<p style="font-size:14px;font-weight: 400">La concurrence' +
        "</p>" +
        '<span style="color:#ddd;font-size:11px">Le Pétrolier' +
        "</span><br>" +
        "<b>" +
          station.petrolier.name +
        "</b><br>" +
        '<span style="color:#ddd;font-size:11px">Nom de la station</span><br>' +
        "<span><b>" +
          station.name +
        "</b></span><br>" +
        "</div>";
    }
    return contentString;
  }

  rad(x) {
    return (x * Math.PI) / 180;
  }

  getDistanceMarker(p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = this.rad(p2.lat() - p1.lat());
    var dLong = this.rad(p2.lng() - p1.lng());
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.lat())) *
        Math.cos(this.rad(p2.lat())) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  }

  getDistance(p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = this.rad(p2.lat - p1.lat);
    var dLong = this.rad(p2.lng - p1.lng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.lat)) *
        Math.cos(this.rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  }

  addStation(station) {
    var body = JSON.stringify(station);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "add/.json", body, { headers: this.headers })
        .toPromise()
        .then(res => resolve(res), error => reject(error));
    });
    return promise;
  }

  editStation(station) {
    var body = JSON.stringify(station);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "edit/" + station.id + ".json", body, {
          headers: this.headers
        })
        .toPromise()
        .then(res => resolve(res), err => reject(err));
    });
    return promise;
  }

  deleteStation(id) {
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "delete/" + id + ".json", { headers: this.headers })
        .toPromise()
        .then(res => resolve(res), err => reject(err));
    });
    return promise;
  }

  polygonCenter(poly) {
    var lowx,
      highx,
      lowy,
      highy,
      lats = [],
      lngs = [],
      vertices = poly.getPath();

    for (var i = 0; i < vertices.length; i++) {
      lngs.push(vertices.getAt(i).lng());
      lats.push(vertices.getAt(i).lat());
    }

    lats.sort();
    lngs.sort();
    lowx = lats[0];
    highx = lats[vertices.length - 1];
    lowy = lngs[0];
    highy = lngs[vertices.length - 1];
    var center_x = lowx + ((highx - lowx) / 2);
    var center_y = lowy + ((highy - lowy) / 2);
    return new google.maps.LatLng(center_x,center_y);
      // this.map.setCenter(new google.maps.LatLng(center_x, center_y));
  }
    //This function get the canter of map in a way that all shapes are shown on the map.
  get_center(array: any[]) {
    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < array.length; i++) {
      switch (array[i].typeobjet_id) {
        case 1:
        var center = JSON.parse(array[i].center);
          var myLatLng = new google.maps.LatLng(center.lat, center.lng);
          bounds.extend(myLatLng);
          break;
        case 2:
        var myLatLng = this.polygonCenter(array[i].overlay);
        bounds.extend(myLatLng);
        break;
        case 3:
        var myLatLng = this.polygonCenter(array[i].overlay);
        bounds.extend(myLatLng);
        break;
        case 5:
        case 4:
        var center = JSON.parse(array[i].center);
        var myLatLng = new google.maps.LatLng(center.lat, center.lng);
        bounds.extend(myLatLng);
        break;
      }
    }
    return bounds;
  }
}
