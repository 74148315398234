import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class UserService {
  public url =  "https://api.total-malaw.com/api/users/";
  public urlroute =  "https://api.total-malaw.com/users/";
  // public url = "http://localhost:8765/api/users/";
  // public urlroute = "http://localhost:8765/users/";
  public headers = new HttpHeaders({
    "content-type": "application/json"
  });
  public user = {
    id: null,
    fn: "",
    ln: "",
    email: "",
    phone: "",
    adresse: "",
    password: "",
    role: "author",
    repassword: ""
  };
  public userModif = {
    id: null,
    fn: "",
    ln: "",
    email: "",
    phone: "",
    adresse: "",
    role: "author"
  };

  constructor(private httpClient: HttpClient) {}

  add(user: any) {
    var body = JSON.stringify(user);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "add.json", body, { headers: this.headers })
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {

            reject(err);
          }
        );
    });
    return promise;
  }

  logincake(user:any){
    var body = JSON.stringify(user);
    console.log(body);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.urlroute + "login.json", body, {headers:this.headers}).toPromise()
        .then(
          res => {
            if(res != null){
              localStorage.setItem('currentUser', JSON.stringify(res['token']));
              localStorage.setItem('user', JSON.stringify(res));
            }
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });

    return promise;
  }

  loginWithToken(token:string){
    var body = JSON.stringify({});
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.urlroute + "login/"+token+".json", body, {headers:this.headers}).toPromise()
        .then(
          res => {
            if(res != null){
              localStorage.setItem('currentUser', JSON.stringify(res['token']));
              localStorage.setItem('user', JSON.stringify(res));
            }
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  checkAuthorization(){
    let test = localStorage.getItem('currentUser');
    // console.log(localStorage.getItem('currentUser'));
    return test;

  }
  destroySession(){
    localStorage.removeItem('currentUser');
    localStorage.removeItem('user');

  }
  edit(user: any) {
    var body = JSON.stringify(user);
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "edit/" + user.id + ".json", body, {
          headers: this.headers
        })
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  delete(userId: any) {
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .post(this.url + "supprimer/" + userId + ".json", {
          headers: this.headers
        })
        .toPromise()
        .then(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
    return promise;
  }

  getEntityById(id: number, entities: any) {
    const entity = entities.find(s => {
      return s.id === id;
    });
    return entity;
  }

  listUsers() {
    if(localStorage.getItem('users') == null){
      let promise = new Promise((resolve, reject) => {
        this.httpClient
          .get(this.url + "index.json")
          .toPromise()
          .then(res =>{
            resolve(res);
            localStorage.setItem('users',JSON.stringify(res));
          }, err => reject(err));
      });
      return promise;
    } else{
      this.httpClient
        .get(this.url + "index.json")
        .subscribe(
          (res) => {
            localStorage.setItem("users", JSON.stringify(res));
          }
        )
      let promise = new Promise((resolve,reject) => {
        resolve(JSON.parse(localStorage.getItem('users')))
      });
      return promise;
    }
  }
  listManagers() {
    if(localStorage.getItem('managers') == null){
      let promise = new Promise((resolve, reject) => {
        this.httpClient
          .get(this.url + "managers.json")
          .toPromise()
          .then(res =>{
            resolve(res);
            localStorage.setItem('managers',JSON.stringify(res));
          }, err => reject(err));
      });
      return promise;
    } else{
      this.httpClient
        .get(this.url + "managers.json")
        .subscribe(
          (res) => {
            localStorage.setItem("managers", JSON.stringify(res));
          }
        )
      let promise = new Promise((resolve,reject) => {
        resolve(JSON.parse(localStorage.getItem('managers')))
      });
      return promise;
    }
  }
  loadUsers(){
    let promise = new Promise((resolve, reject) => {
      this.httpClient
        .get(this.url + "index.json")
        .toPromise()
        .then(res => {
          resolve(res);
          localStorage.setItem('users', JSON.stringify(res));
        }, err => reject(err));
    });
    return promise;
  }
}
