import { Component, TemplateRef, OnInit, Pipe, PipeTransform, Injectable} from '@angular/core';
import { MapService } from '../services/map.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
// import { NgForm, Form } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { MapLoaderService } from '../services/map.loader.service';
// import { BsModalService } from 'ngx-bootstrap/modal/public_api';

declare var google: any;

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
@Injectable()
export class AccueilComponent implements OnInit {

  title = "app-cartographie";
  public fermer: boolean;
  public addstation: boolean = false;
  public disabled: boolean = true;
  public show: boolean = false;
  public p_auto: boolean = true;
  public z_auto: boolean = true;
  public modalRef: BsModalRef;
  limit: number = 11;
  namesearch: any;
  cluster: boolean = true;
  cluster2: boolean = true;
  public buttonName: any = 'right';
  stations: any = [];
  listestations= [];
  markers: any[] = [];
  habillageStation = {
    name: "",
    adresse: null,
    lng: null,
    lat: null,
    volume: null,
    zone_id: null,
    petrolier_id: null,
    type_id: null,
    secteur_id: null,
    gestion_id: null,
    etat_id: null
  }
  stationAEditer: any;
  petroliers: any[] = [];
  zones: any[] = [];
  map: any = null;
  checkAllPetro: boolean = false;
  checkAllZone: boolean = false;
  zonesOrder: any[] = [];
  petroliersOrder: any[] = [];
  constructor(
    private mapService: MapService,private  userService: UserService,
    private modalService: BsModalService, private spinner: NgxSpinnerService, private router: Router
  ) {
  }
  ngOnInit() {
    if(this.userService.checkAuthorization() ==null){
      return this.router.navigate(['/auth'])
    }
    else{
      this.chargerDatas();
    }
    //  (this.map);
  }
  ngAfterViewInit() {
    // this.mapService.ngAfterViewInit(this.map);
    MapLoaderService.load().then(() => {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: 14.2761, lng: -14.45105 },
        zoom: 8
      });
      this.checkAllPetro = true;
      this.checkAllZone = true;
      this.updateAll(this.checkAllPetro);
    });
  }

  sliceString(val: string, taille:number) {
    if(val.length > taille) {
      return (val.substr(0,taille)+'..').toUpperCase();
    }
    return val.toUpperCase();
  }

  viewAll() {
    this.limit = this.tailleTableau(this.stations);
  }
  viewReduce() {
    this.limit = 11;
  }
  logout(){
    this.userService.destroySession();
    this.router.navigate(['/auth']);
  }
  clusterChange(){
    this.mapService.creationMarkeurs(this.stations, this.markers, this.map,this.cluster);
  }
  clusterChangeSurete(){
    console.log(this.cluster2);
    
    this.mapService.creationMarkeurs(this.stations, this.markers, this.map,this.cluster2,true);
  }
  toggle() {
    this.show = !this.show;
    // CHANGE THE NAME OF THE BUTTON.
    if (this.show)
      this.buttonName = "left";
    else
      this.buttonName = "right";
  }
  public openModal(template: TemplateRef<any>, station: any) {

    this.modalRef = this.modalService.show(template); // {3}
    this.stationAEditer = {
      id: station.id,
      name: station.name,
      lat: station.lat,
      lng: station.lng,
      petrolier_id: station.petrolier_id,
      zone_id: station.zone_id,
      type_id: station.type_id
    };
  }

  nbrEntites(nom: string) {
    var nbr = 0;
    if (nom == "petrolier") {
      for (var i in this.petroliers) {
        if (this.petroliers[i].check == true) {
          nbr++;
        }
      }
    } else if (nom == "zone") {
      for (var i in this.zones) {
        if (this.zones[i].check == true) {
          nbr++;
        }
      }
    } else {
      return false;
    }
    return nbr;
  }

  checkPetrolier(petrolier: any) {
    if (petrolier.check == true) {
      this.mapService.loadMarkers(
        this.stations,
        this.zones,
        this.petroliers,
        petrolier.id,
        "petrolier",
        "remplir",
        this.p_auto
      );
      this.mapService.filtrage(
        this.stations,
        this.markers,
        this.zones,
        this.petroliers
      );
      this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
    } else {
      this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
      if (this.nbrEntites("petrolier") <= 0) {
        this.mapService.loadMarkers(
          this.stations,
          this.zones,
          this.petroliers,
          petrolier.id,
          "petrolier",
          "vider",
          this.p_auto
        );
      }
      if (this.checkAllPetro === true) {
        this.checkAllPetro = false;
      }
      this.mapService.filtrage(
        this.stations,
        this.markers,
        this.zones,
        this.petroliers
      );
      this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
      this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
    }
    this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
  }

  checkZone(zone: any) {
    if (this.nbrEntites("petrolier") <= 0) {
      if (zone.check === true) {
        this.mapService.loadMarkers(
          this.stations,
          this.zones,
          this.petroliers,
          zone.id,
          "zone",
          "remplir",
          this.z_auto
        );
        this.mapService.filtrage(
          this.stations,
          this.markers,
          this.zones,
          this.petroliers
        );
        this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
      }
    } else {
      if (zone.check === true) {
        this.mapService.loadMarkers(
          this.stations,
          this.zones,
          this.petroliers,
          zone.id,
          "zone",
          "remplir",
          this.z_auto
        );
        this.mapService.filtrage(
          this.stations,
          this.markers,
          this.zones,
          this.petroliers
        );
        this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
      } else {
        this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
        if (this.checkAllPetro === true) {
          this.checkAllPetro = false;
        }
        if (this.nbrEntites("zone") <= 0) {
          this.mapService.loadMarkers(
            this.stations,
            this.zones,
            this.petroliers,
            zone.id,
            "zone",
            "vider",
            this.z_auto
          );
        }
        this.mapService.filtrage(
          this.stations,
          this.markers,
          this.zones,
          this.petroliers
        );
        this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
        this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
      }
    }
    this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
  }

  checkAll(type:any = false) {
    if((type == "zone" && this.z_auto) || (type == "petrolier" && this.p_auto)) {
      type = false;
      this.checkAllPetro = true;
      this.checkAllZone = true;
    }
    this.mapService.checkAll(this.stations, this.zones, this.petroliers,type);
    this.mapService.filtrage(
      this.stations,
      this.markers,
      this.zones,
      this.petroliers
    );
    this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
  }

  hideAll(type:any = false) {
    if((type == "zone" && this.z_auto) || (type == "petrolier" && this.p_auto)) {
      type = false;
      this.checkAllPetro = false;
      this.checkAllZone = false;
    }
    this.mapService.hideAll(this.stations, this.zones, this.petroliers,type);
    this.mapService.filtrage(
      this.stations,
      this.markers,
      this.zones,
      this.petroliers
    );
    this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
  }

  updateAll(check: boolean, type:any = false) {
    if (check == true) this.checkAll(type);
    else this.hideAll(type);
  }
  checkStation(station: any) {

    var petrolier = this.petroliers[station.petrolier_id];
    var zone = this.zones[station.zone_id];
    if (station.check === true) {
      if (petrolier.check === false) {
        petrolier.check = true;
      }
      if (zone.check === false) {
        zone.check = true;
      }
      this.mapService.filtrage(
        this.stations,
        this.markers,
        this.zones,
        this.petroliers
      );
    } else {
      this.mapService.filtrage(
        this.stations,
        this.markers,
        this.zones,
        this.petroliers
      );
      this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
    }
    this.mapService.creationMarkeurs(this.stations, this.markers, this.map, this.cluster);
    this.mapService.recentrer(this.stations,this.zones,this.petroliers, this.map);
  }

  tailleTableau(obj){
    var size =0,key;
    for(key in obj){
      if(obj.hasOwnProperty(key)){
        size++;
      }
    }
    return size;
  }

  chargerDatas() {
    this.spinner.show()
    this.mapService
      .init()
      .then(reponse => {
        this.stations = reponse["stations"];
        this.listestations = reponse["listestations"];
        this.zones = reponse["zones"];
        this.petroliers = reponse["petroliers"];
        this.zonesOrder = this.mapService.trierObjet(reponse["zones"]);
        this.petroliersOrder = this.mapService.trierObjet(reponse["petroliers"]);
        if(reponse !== null){
          this.spinner.hide();
          this.disabled = false;
        }
      })
      .catch(err => {
         (err);
    });

  }
}
