import { Component, OnInit } from '@angular/core';
import { AccueilComponent } from '../accueil/accueil.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user;
  constructor(private f: AccueilComponent) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.user = this.user['fn']+" "+this.user['ln'];
    
  }
  logout(){
    this.f.logout();
  }

}
