import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router'
import { UserService } from '../../services/user.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CrudService } from '../../services/crud.service';
import { MapService } from '../../services/map.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: "app-parametres",
  templateUrl: "./parametres.component.html",
  styleUrls: ["./parametres.component.css"]
})
export class ParametresComponent implements OnInit {
  fermer: boolean = true;
  user = this.userService.user;
  users:any;
  stations:any;
  zones:any;
  petroliers: any;
  station: any = this.crudService.station;
  secteurs = this.crudService.secteurs;
  gestions = this.crudService.gestions;
  types = this.crudService.types;
  btnAjoutStation = "Ajouter";
  btnAjoutUser = "Ajouter";
  btnSupStation = "Supprimer";
  public modalRef: BsModalRef;
  map: any;
  userAmodifier = this.userService.userModif;
  constructor(
    private router: Router,
    private modalService: BsModalService,
    private userService: UserService,
    private crudService: CrudService,
    private mapService: MapService,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    if(this.userService.checkAuthorization() ==null){
      return this.router.navigate(['/auth'])
    }
    this.chargerDatas();
  }
  ngAfterViewInit() {
    // this.mapService.ngAfterViewInit(this.map);
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template); // {3}
  }

  close() {
    this.router.navigate(["/home"]);
  }
  addUser() {
    this.btnAjoutUser = "Ajout eu cours ...";
    if(this.user.password == this.user.repassword){
      this.userService.add(this.user).then(res => {
        if (res != null) {
          this.btnAjoutUser = "Ajouté avec succès";
          this.modalRef.hide();
          this.router.navigate(["/home"]);
          this.loadUSers();
        } else {
          alert("Echec de l'operation ");
          this.btnAjoutUser = "Ajouter";
        }
      },
        err => { alert("Echec de l'operation "); this.btnAjoutUser = "Ajouter"; }
      ).catch(
        err => { alert("Echec de l'operation "); this.btnAjoutUser = "Ajouter"; }
      )
    } else{
      alert("Les deux mots ne correspondent pas");
      this.btnAjoutUser = "Ajouer";
    }
  }
  editUser(user: any) {
    this.userService.edit(this.userAmodifier).then(
      resolve => {
        if (resolve != null) {
          this.modalRef.hide();
          user = resolve;
          alert("User modifié avec succès !");
          this.loadUSers();
        } else {
          console.log("Echec de l'operation null (renvoyé)");
        }
      },
      reject => {
        console.log(reject);
      }
    );
  }
  deleteUSer(id) {
    this.userService
      .delete(id)
      .then(res => console.log(res), err => console.log(err));
  }
  loadUSers() {
    this.userService
      .listUsers()
      .then(res => {
        if (res != undefined) {
          this.users = res;
        }
      })
      .catch(err => {
        console.log("Erreur lors de l'envoi de la requete");
      });
  }
  chargerDatas() {
    this.spinner.show()
    this.loadUSers();
    this.mapService
      .init()
      .then(reponse => {
        this.stations = reponse["stations"];
        this.zones = reponse["zones"];
        this.petroliers = reponse["petroliers"];
        if (reponse !== null) {
          this.spinner.hide();
        }
      })
      .catch(err => {
        console.log("Catch erreur : " + err);
      });
  }
}
