import { Component, TemplateRef } from '@angular/core';
import { MapService } from './services/map.service';
// import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgForm, Form } from '@angular/forms';
declare var google: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "app-cartographie";
  // public modalRef: BsModalRef;
  stations: any;
  markers: any[] = [];
  zones: any;
  habillageStation = {
    name: "",
    adresse: null,
    lng: null,
    lat: null,
    volume: null,
    zone_id: null,
    petrolier_id: null,
    type_id: null,
    secteur_id: null,
    gestion_id: null,
    etat_id: null
  }
  stationAEditer: any;
  petroliers: any;
  map: any;
  checkAllPetro: boolean = false;
  constructor(
    private mapService: MapService,
    // private modalService: BsModalService
  ) {
    // this.chargerDatas();
  }
  ngOnInit() {}
  ngAfterViewInit() {
    // this.mapService.ngAfterViewInit(this.map);
  }
}
