import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { MapsAPILoader, AgmMap } from "@agm/core";

import { MapService } from '../services/map.service';
import { Marker } from '@agm/core/services/google-maps-types';
import { UserService } from '../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

declare var google: any;

@Component({
  selector: "app-rayon",
  templateUrl: "./rayon.component.html",
  styleUrls: ["./rayon.component.css"]
})
export class RayonComponent implements OnInit {
  @ViewChild(AgmMap,{static: false}) agmMap: AgmMap;
  buttonName= "down";
  public show :boolean= false;
  lat = this.mapService.centerLat;
  lng = this.mapService.centerLng;
  circleRadius = 20000;
  fitBounds: boolean = false;
  petroliers: any;
  stations: any;
  zones: any;
  ready = false;
  opacite = [
    { 'id': 0.2 },
    { 'id': 0.3 },
    { 'id': 0.4 },
    { 'id': 0.5 },
    { 'id': 0.6 },
    { 'id': 0.7 },
    { 'id': 0.8 },
    { 'id': 0.9 },
    { 'id': 1}
  ];
  colors = this.mapService.colors;
  strokeColor = this.colors[0].code;
  fillColor = this.colors[0].code;
  strokeOpacite = 1;
  fillOpacity = 0.5;
  strokeGras = 5;
  btnSearch = "rechercher";
  rayonkm = this.circleRadius/1000;
  filteredMarkers = []; //conteneurs des markeurs filtres
  filteredPetroliers = []; //conteneurs des markeurs filtres
  itemSelected: number;
  constructor(
    private mapService: MapService,
    public mapsApiLoader: MapsAPILoader,
    public userService : UserService,
    public spinner : NgxSpinnerService,
    public router: Router
  ) { }

  ngOnInit() {
    if(this.userService.checkAuthorization() ==null){
      return this.router.navigate(['/auth'])
    }

    this.mapService.init().then(res => {
      this.petroliers = res["petroliers"];
      this.stations = res["stations"];
      this.zones = res["zones"];
      // this.mapsApiLoader.load().then(() => {
      //   this.filtrage();
      // });
    });
  }

  logout(){
    this.userService.destroySession();
  }

  accueil() {
    this.router.navigateByUrl("/home");
  }

  openedWindow: number = 0; // alternative: array of numbers

  openWindow(id) {
    this.openedWindow = id; // alternative: push to array of numbers
    this.itemSelected = id
  }

  isInfoWindowOpen(id) {
    return this.openedWindow == id; // alternative: check if id is in array
  }

  toggle() {
    this.show = !this.show;
    // CHANGE THE NAME OF THE BUTTON.
    if (this.show)
      this.buttonName = "right";
    else
      this.buttonName = "down";
  }

  markerDragEnd(event) {
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.show = false;
    this.rayonkm = this.circleRadius / 1000;

  }
  updateCircle(event) {
    this.lat = event.lat;
    this.lng = event.lng;
    this.show = false;
    this.rayonkm = this.circleRadius / 1000;
  }

  circleDragEnd(event) {
    this.show = false;
    this.rayonkm = this.circleRadius / 1000;
  }
  onRadiusChange() {
    // this.filtrage();
    this.show = false;
    this.rayonkm = this.circleRadius / 1000;
  }

  changeCircle(event: any){
    // alert(this.rayonkm);
    this.circleRadius = this.rayonkm * 1000;

  }

  changeCirclepress(event: any) {
    // alert(this.rayonkm);
    this.circleRadius = this.rayonkm * 1000;

  }

  checkedChange(petrolier) {
    petrolier.checked = !petrolier.checked
  }

  mapIdle() {
    // console.log('je suis la');
    // this.selected(this.itemSelected)
  }

  selected(id: number) {
    this.itemSelected = id
    this.openWindow(id)
  }

  filtrage() {
    this.filteredMarkers = [];
    this.spinner.show();
    this.btnSearch = "recherche en cours ...";
    setTimeout(() => {
        this.spinner.hide();
        this.toggle();
        this.btnSearch = "recherche";

    }, 2000);

    const center = new google.maps.LatLng(this.lat, this.lng);
    for (var i in this.stations) {
      var marker = this.stations[i];
      const markerLoc = new google.maps.LatLng(marker.lat, marker.lng);
      const distanceInKm = google.maps.geometry.spherical.computeDistanceBetween(
        markerLoc,
        center
      );
      if (distanceInKm < this.circleRadius) {
        this.filteredMarkers.push(marker);
      }
    }

    this.fitBounds = false

    this.filteredPetroliers = []
    if(this.filteredMarkers.length > 0) {
      for(var n in this.petroliers) {
        let petrolier = this.petroliers[n]
        petrolier.stations = []
        petrolier.checked = true
        //Filtered markers
        this.filteredMarkers.find(marker => {
          if(marker.petrolier_id == petrolier.id) petrolier.stations.push(marker);
        });

        if(petrolier.stations.length > 0) this.filteredPetroliers.push(petrolier);

        if(this.filteredMarkers.length > 0) this.fitBounds = true
      }
    }
  }
}
